import * as React from "react"
import { Link } from "gatsby"


// Style
import './notFound.scss';

import notFoundImage from '../images/404.jpg'

// markup
const NotFoundPage = () => {
  return (
    <main className='notFound'>
      <title>Not found</title>
      <img className='background' src={notFoundImage} alt="" />

      <div className="content">
        <svg xmlns="http://www.w3.org/2000/svg" width="78.654" height="80.28" viewBox="0 0 78.654 80.28">
          <g id="Groupe_264" data-name="Groupe 264" transform="translate(-932.5 -302.345)">
            <path id="Tracé_7" data-name="Tracé 7" d="M35.465,45.442H70.548a40.293,40.293,0,0,0-3.2-7.815H35.637A19.572,19.572,0,0,1,16.184,19.82,40.6,40.6,0,0,0,8.9,23.933,27.422,27.422,0,0,0,35.465,45.442Z" transform="translate(939.022 285.817)" fill="#d6ffda"/>
            <path id="Tracé_8" data-name="Tracé 8" d="M36.152,31.885H54.645a40.91,40.91,0,0,0-9.084-7.832H36.255a8.775,8.775,0,0,1-8.312-5.981c-1.217-.12-2.451-.171-3.7-.171a41.5,41.5,0,0,0-4.37.24A16.659,16.659,0,0,0,36.152,31.885Z" transform="translate(946.853 284.445)" fill="#d6ffda"/>
            <path id="Tracé_9" data-name="Tracé 9" d="M35.487,47.62A27.422,27.422,0,0,0,8.87,69.352,40.023,40.023,0,0,0,16.188,73.5a19.555,19.555,0,0,1,19.47-18.047H67.519a39.094,39.094,0,0,0,3.119-7.832Z" transform="translate(939.001 305.834)" fill="#d6ffda"/>
            <path id="Tracé_10" data-name="Tracé 10" d="M36.166,56.39A16.659,16.659,0,0,0,19.85,70.29a41.886,41.886,0,0,0,4.4.24,25.668,25.668,0,0,0,3.633-.171,8.844,8.844,0,0,1,8.381-6.067h9.546a39.42,39.42,0,0,0,8.946-7.832h-18.6Z" transform="translate(946.839 312.095)" fill="#d6ffda"/>
            <path id="Tracé_11" data-name="Tracé 11" d="M24.578,54.261h53.88c.12-1.268.189-2.554.189-3.873a27.671,27.671,0,0,0-.189-3.891H24.75a19.908,19.908,0,0,1-19.838-18A39.089,39.089,0,0,0,0,39.128,27.831,27.831,0,0,0,24.578,54.261Z" transform="translate(932.5 292.069)" fill="#d6ffda"/>
          </g>
        </svg>
        <h1>This page was lost</h1>
        <p>We sincerely apologize but the page you're looking for cannot be found. Your may have mistyped the address or the page may have moved.</p>
        <div className="buttons">
          <Link to='/'><button>Aller à l'accueil</button></Link>
          <Link to='/#contact'><button>Nous contacter</button></Link>
        </div>

      </div>
    </main>
  )
}

export default NotFoundPage
